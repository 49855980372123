import React from "react";
import "./Page404.scss";
import { NavLink } from "react-router-dom";

export const Page404 = () => {
    return (
        <div className="content not-found">
            <h1>Deze pagina wordt niet gevonden</h1>
            <p>
                Klik{" "}
                <NavLink exact to="/" className="choose-other-area">
                    <span>hier</span>{" "}
                </NavLink>
                om terug te keren naar de login pagina.
            </p>
        </div>
    );
};
