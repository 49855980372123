import { all, fork } from "redux-saga/effects";
import loginSaga from "./pages/login/login.saga";
import synonymSaga from "./pages/administration/synonym/synonym.saga";
import reindexSaga from "./pages/administration/reindex/reindex.saga";
import countsTableSaga from "./pages/administration/countsTable/countsTable.saga";

function* rootSaga() {
    yield all([fork(loginSaga), fork(synonymSaga), fork(reindexSaga), fork(countsTableSaga)]);
}

export default rootSaga;
