export const DO_REINDEX_SHOP = "DO_REINDEX_SHOP";
export const RECEIVE_REINDEX_STATUS_SHOP = "RECEIVE_REINDEX_STATUS_SHOP";

export function doReindexShop() {
    return { type: DO_REINDEX_SHOP };
}

export function receiveReindexStatusShop(payload: number) {
    return { type: RECEIVE_REINDEX_STATUS_SHOP, payload };
}
