import React from "react";
import { Loader, Table } from "semantic-ui-react";
import { SearchedTerm } from "../countsTable.model";

interface MetricsTableProps {
    isLoading: boolean;
    headerCellOne: string;
    headerCellTwo: string;
    metricsList: any;
}

function MetricsTable({ isLoading, headerCellOne, headerCellTwo, metricsList }: MetricsTableProps) {
    if (isLoading) {
        return (
            <Loader active inline="centered" size="medium">
                De lijst wordt opgehaald.
            </Loader>
        );
    }
    return (
        <>
            <div className={"metricsTable"}>
                <Table color={"red"} columns={2}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{headerCellOne}</Table.HeaderCell>
                            <Table.HeaderCell>{headerCellTwo}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {metricsList &&
                            metricsList.map((metricsTerm: SearchedTerm, index: number) => (
                                <Table.Row key={index}>
                                    <Table.Cell>{metricsTerm.key}</Table.Cell>
                                    <Table.Cell>{metricsTerm.count}</Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </div>
        </>
    );
}

export default MetricsTable;
