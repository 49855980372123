import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { API_URL } from "../../api";
import { setAxiosAuthorizationHeaders } from "../../components/helpers/axios-helpers";
import { receiveLoginError, receiveLoginToken, SAVE_LOGIN } from "./login.actions";
import { LOCAL_STORAGE_TOKEN, LoginSchema } from "./login.model";

export function* saveLoginSchema(action: { type: string; payload: LoginSchema }) {
    try {
        const result = yield call(axios.post, API_URL.LOGIN, action.payload);
        const loginToken = result.data;

        yield put(receiveLoginToken(loginToken.token));

        localStorage.setItem(LOCAL_STORAGE_TOKEN, loginToken.token);
        setAxiosAuthorizationHeaders(loginToken.token);
    } catch (error) {
        yield put(receiveLoginError(error));
        setAxiosAuthorizationHeaders(null);
    }
}

function* loginSaga() {
    yield takeEvery(SAVE_LOGIN, saveLoginSchema);
}

export default loginSaga;
