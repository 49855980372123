import { combineReducers } from "redux";
import { loginReducer } from "./pages/login/login.reducer";
import { synonymReducer } from "./pages/administration/synonym/synonym.reducer";
import { reindexReducer } from "./pages/administration/reindex/reindex.reducer";
import { countsTableReducer } from "./pages/administration/countsTable/countsTable.reducer";

const rootReducer = combineReducers({
    loginReducer,
    synonymReducer,
    reindexReducer,
    countsTableReducer,
});

export default rootReducer;
