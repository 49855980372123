import { Reducer } from "redux";
import {
    GET_MOST_FOUND_PRODUCTS,
    GET_MOST_SEARCHED_TERMS,
    GET_ZERO_HITS,
    RECEIVE_HITS_STATUS,
    RECEIVE_MOST_FOUND_PRODUCTS_LIST,
    RECEIVE_MOST_SEARCHED_TERMS_LIST,
    RECEIVE_ZERO_HITS_LIST,
} from "./countsTable.actions";
import { ProductCounts, SearchedTerm } from "./countsTable.model";
import { Action } from "../../action.model";

export type CountsTableReducerState = {
    isLoading: boolean;
    mostSearchedTermsList: SearchedTerm[] | undefined;
    mostFoundProductsList: ProductCounts[] | undefined;
    zeroHitsList: SearchedTerm[] | undefined;
    countsStatus: number | null;
};

const initialState = {
    isLoading: false,
    mostSearchedTermsList: undefined,
    mostFoundProductsList: undefined,
    zeroHitsList: undefined,
    countsStatus: null,
};

const convertToArray = (obj: any) => {
    const terms = Object.entries(obj);
    const metrics: any = [];

    for (let [key, value] of terms) {
        metrics.push({
            key: key,
            count: value,
        });
    }

    metrics.sort((a: any, b: any) => (a.count > b.count ? -1 : b.count > a.count ? 1 : 0));

    return metrics;
};

export function countsTableReducer(state: CountsTableReducerState = initialState, action: Action) {
    switch (action.type) {
        case GET_MOST_SEARCHED_TERMS:
            return Object.assign({}, state, {
                isLoading: true,
            });

        case RECEIVE_MOST_SEARCHED_TERMS_LIST:
            return Object.assign({}, state, {
                isLoading: false,
                mostSearchedTermsList: convertToArray(action.payload),
            });

        case GET_MOST_FOUND_PRODUCTS:
            return Object.assign({}, state, {
                isLoading: true,
            });

        case RECEIVE_MOST_FOUND_PRODUCTS_LIST:
            return Object.assign({}, state, {
                isLoading: false,
                mostFoundProductsList: convertToArray(action.payload),
            });

        case GET_ZERO_HITS:
            return Object.assign({}, state, {
                isLoading: true,
            });

        case RECEIVE_ZERO_HITS_LIST:
            return Object.assign({}, state, {
                isLoading: false,
                zeroHitsList: convertToArray(action.payload),
            });

        case RECEIVE_HITS_STATUS:
            return Object.assign({}, state, {
                countsStatus: action.payload,
            });

        default:
            return state;
    }
}

export default countsTableReducer as Reducer<CountsTableReducerState>;
