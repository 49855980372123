import { Reducer } from "redux";
import { ADD_SYNONYM_ITEM, DELETE_SYNONYM_ITEM, GET_DOWNLOAD_SYNONYMS, GET_SYNONYM_LIST, RECEIVE_SYNONYM_DOWNLOAD_LIST, RECEIVE_SYNONYM_LIST, RECEIVE_SYNONYM_STATUS } from "./synonym.actions";
import { Action } from "../../action.model";
import { SynonymListItem } from "./synonym.model";

export type SynonymReducerState = {
    isLoading: boolean;
    synonymList: SynonymListItem[] | undefined;
    newTermAndSynonyms: SynonymListItem | undefined;
    synonymListItem: SynonymListItem | undefined;
    downloadList: string;
    synonymStatus: number | null;
};

const initialState = {
    isLoading: false,
    synonymList: undefined,
    newTermAndSynonyms: undefined,
    synonymListItem: undefined,
    downloadList: "",
    synonymStatus: null,
};

export function synonymReducer(state: SynonymReducerState = initialState, action: Action) {
    switch (action.type) {
        case GET_SYNONYM_LIST:
            return Object.assign({}, state, {
                isLoading: true,
            });

        case RECEIVE_SYNONYM_LIST:
            return Object.assign({}, state, {
                isLoading: false,
                synonymList: action.payload,
            });

        case ADD_SYNONYM_ITEM:
            return Object.assign({}, state, {
                newTermAndSynonyms: action.payload,
            });

        case DELETE_SYNONYM_ITEM:
            return Object.assign({}, state, {
                synonymListItem: action.payload,
            });

        case GET_DOWNLOAD_SYNONYMS:
            return Object.assign({}, state, {
                isLoading: true,
            });

        case RECEIVE_SYNONYM_DOWNLOAD_LIST:
            return Object.assign({}, state, {
                isLoading: false,
                downloadList: action.payload,
            });

        case RECEIVE_SYNONYM_STATUS:
            return Object.assign({}, state, {
                synonymStatus: action.payload,
            });

        default:
            return state;
    }
}

export default synonymReducer as Reducer<SynonymReducerState>;
