import { Reducer } from "redux";
import { setAxiosAuthorizationHeaders } from "../../components/helpers/axios-helpers";
import { Action } from "../action.model";
import { LOGOUT, RECEIVE_LOGIN_ERROR, RECEIVE_LOGIN_TOKEN, SAVE_LOGIN } from "./login.actions";
import { LOCAL_STORAGE_TOKEN, LoginSchema } from "./login.model";

export type LoginReducerState = {
    loginData: LoginSchema | null;
    loginToken: string | null;
    loginError: string | null;
};

const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
if (token) {
    setAxiosAuthorizationHeaders(token);
}

const initialState = {
    loginData: null,
    loginToken: token || null,
    loginError: null,
};

export function loginReducer(state: LoginReducerState = initialState, action: Action) {
    switch (action.type) {
        case SAVE_LOGIN:
            return Object.assign({}, state, {
                loginData: action.payload,
            });

        case RECEIVE_LOGIN_TOKEN:
            return Object.assign({}, state, {
                loginToken: action.payload,
                loginError: null,
            });

        case RECEIVE_LOGIN_ERROR:
            return Object.assign({}, state, {
                loginToken: null,
                loginError: action.payload,
            });

        case LOGOUT:
            return Object.assign({}, state, {
                loginToken: null,
                loginError: null,
                loginData: null,
            });

        default:
            return state;
    }
}

export default loginReducer as Reducer<LoginReducerState>;
