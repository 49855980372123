import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { API_URL } from "../../../api";
import {
    GET_MOST_FOUND_PRODUCTS,
    GET_MOST_SEARCHED_TERMS,
    GET_ZERO_HITS,
    receiveHitsStatus,
    receiveMostFoundProductsList,
    receiveMostSearchedTermsList,
    receiveZeroHitsList,
} from "./countsTable.actions";

export function* fetchMostSearchedTerms() {
    try {
        const result = yield call(axios.get, API_URL.MOST_SEARCHED_TERMS);
        const mostSearchedTermsList = result.data;

        yield put(receiveMostSearchedTermsList(mostSearchedTermsList));
    } catch (error) {
        const countsStatus = error.request;

        yield put(receiveHitsStatus(countsStatus));
    }
}

export function* fetchMostFoundProducts() {
    try {
        const result = yield call(axios.get, API_URL.MOST_FOUND_PRODUCTS);
        const mostFoundProducts = result.data;

        yield put(receiveMostFoundProductsList(mostFoundProducts));
    } catch (error) {
        const countsStatus = error.request;

        yield put(receiveHitsStatus(countsStatus));
    }
}

export function* fetchZeroHits() {
    try {
        const result = yield call(axios.get, API_URL.ZERO_HITS);
        const zeroHitsList = result.data;

        yield put(receiveZeroHitsList(zeroHitsList));
    } catch (error) {
        const countsStatus = error.request;

        yield put(receiveHitsStatus(countsStatus));
    }
}

function* countsTableSaga() {
    yield takeEvery(GET_MOST_SEARCHED_TERMS, fetchMostSearchedTerms);
    yield takeEvery(GET_MOST_FOUND_PRODUCTS, fetchMostFoundProducts);
    yield takeEvery(GET_ZERO_HITS, fetchZeroHits);
}

export default countsTableSaga;
