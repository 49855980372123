import "./navigation.scss";
import React from "react";
import { Button, Header, Image, Segment } from "semantic-ui-react";
import Logo from "../../images/logo/Logo-avatar.svg";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { LoginReducerState } from "../../pages/login/login.reducer";
import { connect } from "react-redux";

interface NavigationProps extends RouteComponentProps<any> {
    loginToken: string | null;
}

export class Navigation extends React.Component<NavigationProps> {
    clearLocalStorage = () => {
        localStorage.clear();
        window.location.reload();
    };

    render() {
        const { loginToken } = this.props;

        if (loginToken === null) {
            return null;
        }

        return (
            <Segment className="navigation" inverted color="red" attached={true} clearing>
                <Header as="h4" floated="right">
                    <Button className="logout-button" basic inverted onClick={this.clearLocalStorage}>
                        Uitloggen
                    </Button>
                </Header>
                <Header as="h5" floated="left">
                    <NavLink exact to="/" activeClassName="active">
                        <Image className="logoImg" src={Logo} size="mini" verticalAlign="middle" /> <span>Huuskes search beheer</span>
                    </NavLink>
                </Header>
            </Segment>
        );
    }
}

const mapStateToProps = (state: { loginReducer: LoginReducerState }) => ({
    loginToken: state.loginReducer.loginToken,
});

export default withRouter(
    connect(
        mapStateToProps,
        null,
    )(Navigation),
);
