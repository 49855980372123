import React, { Component } from "react";
import { Button, Loader } from "semantic-ui-react";
import axios from "axios";
import { API_URL } from "../../../api";

interface ReindexCatalogProps {
    handleButtonClick: (clusterId: string) => void;
    isLoading: boolean;
    reindexStatus: number | null;
}

export class ReindexCatalog extends Component<ReindexCatalogProps, any> {
    constructor(props: any) {
        super(props);
        this.state = { data: "" };
    }

    async componentDidMount(): Promise<void> {
        const response = await axios.get(API_URL.GET_INDEX_SHOP);
        this.setState({ data: response.data });
    }

    handleButtonClick = () => {
        this.props.handleButtonClick("SHOP");
    };

    render() {
        const { isLoading, reindexStatus } = this.props;

        if (!isLoading && !reindexStatus) {
            return (
                <div>
                    <h1>Shop</h1>
                    <p>Herindexer alle beschikbare producten.</p>
                    <Button className="reindex-button" color="red" onClick={this.handleButtonClick}>
                        Herindexeren
                    </Button>
                    <br />
                    <p>Actieve index: {this.state.data}</p>
                </div>
            );
        }

        if (!isLoading && reindexStatus === 200) {
            return (
                <div className="reindex-status-message">
                    <h1>Shop</h1>
                    <p>Reindex succesvol.</p>
                    <Button className="reindex-button" color="red" onClick={this.handleButtonClick}>
                        Herindexeren
                    </Button>
                </div>
            );
        }

        if (!isLoading && reindexStatus !== 200) {
            return (
                <div className="reindex-status-message">
                    <p>Er is wat mis gegaan.</p>
                    <p>Probeer later het nog een keer.</p>
                    <p>Error code: ({reindexStatus})</p>
                </div>
            );
        }

        return (
            <Loader active inline="centered">
                De reindex duurt ongeveer 1:30u
            </Loader>
        );
    }
}
