import { LoginSchema } from "./login.model";

export const SAVE_LOGIN = "SAVE_LOGIN";
export const RECEIVE_LOGIN_TOKEN = "RECEIVE_LOGIN_TOKEN";
export const RECEIVE_LOGIN_ERROR = "RECEIVE_LOGIN_ERROR";
export const LOGOUT = "LOGOUT";

export function saveLogin(payload: LoginSchema) {
    return { type: SAVE_LOGIN, payload };
}

export function receiveLoginToken(payload: string) {
    return { type: RECEIVE_LOGIN_TOKEN, payload };
}

export function receiveLoginError(payload: string) {
    return { type: RECEIVE_LOGIN_ERROR, payload };
}

export function logout() {
    return { type: LOGOUT };
}
