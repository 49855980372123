import axios from "axios";
import { Store } from "redux";
import { logout } from "../../pages/login/login.actions";

export const createAxiosInterceptors = (store: Store) => {
    const succes = (response: any) => response;

    const error = (error: any) => {
        if (error.response.status === 401) {
            store.dispatch(logout());
        }
        return Promise.reject(error);
    };

    axios.interceptors.response.use(succes, error);
};

export const setAxiosAuthorizationHeaders = (token: string | null) => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        axios.defaults.headers.common["Authorization"] = "";
    }
};
