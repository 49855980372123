import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import App from "./App";
import { createAxiosInterceptors } from "./components/helpers/axios-helpers";
import "./index.scss";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";
import * as serviceWorker from "./serviceWorker";

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, compose(applyMiddleware(loggerMiddleware, sagaMiddleware)));

sagaMiddleware.run(rootSaga);

createAxiosInterceptors(store);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
