import { Reducer } from "redux";
import { Action } from "../../action.model";
import { DO_REINDEX_SHOP, RECEIVE_REINDEX_STATUS_SHOP } from "./reindex.actions";

export type ReindexReducerState = {
    SHOP: {
        isLoading: boolean;
        reindexStatus: number | null;
    };
};

const initialState = {
    SHOP: {
        isLoading: false,
        reindexStatus: null,
    },
};

export function reindexReducer(state: ReindexReducerState = initialState, action: Action) {
    switch (action.type) {
        case DO_REINDEX_SHOP:
            return Object.assign({}, state, {
                SHOP: { isLoading: true },
            });

        case RECEIVE_REINDEX_STATUS_SHOP:
            return Object.assign({}, state, {
                SHOP: { isLoading: false, reindexStatus: action.payload },
            });

        default:
            return state;
    }
}

export default reindexReducer as Reducer<ReindexReducerState>;
