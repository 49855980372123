import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { CountsTableReducerState } from "./countsTable.reducer";
import { getMostFoundProducts, getMostSearchedTerms, getZeroHits } from "./countsTable.actions";
import { Divider, Tab } from "semantic-ui-react";
import MetricsTable from "./metricsTable/MetricsTable";

interface HitsTableProps extends RouteComponentProps<any> {
    countsTableReducerState: CountsTableReducerState;
    getMostSearchedTerms: () => void;
    getMostFoundProducts: () => void;
    getZeroHits: () => void;
}

export class CountsTable extends Component<HitsTableProps, {}> {
    componentDidMount(): void {
        this.props.getMostSearchedTerms();
        this.props.getMostFoundProducts();
        this.props.getZeroHits();
    }

    panes = [
        {
            menuItem: "Meeste gezochte termen",
            render: () => (
                <Tab.Pane>
                    {" "}
                    <MetricsTable
                        isLoading={this.props.countsTableReducerState.isLoading}
                        headerCellOne={"Termen"}
                        headerCellTwo={"Aantal"}
                        metricsList={this.props.countsTableReducerState.mostSearchedTermsList}
                    />{" "}
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Meeste gevonden producten",
            render: () => (
                <Tab.Pane>
                    {" "}
                    <MetricsTable
                        isLoading={this.props.countsTableReducerState.isLoading}
                        headerCellOne={"Productnummer"}
                        headerCellTwo={"Aantal"}
                        metricsList={this.props.countsTableReducerState.mostFoundProductsList}
                    />{" "}
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Nul hit termen",
            render: () => (
                <Tab.Pane>
                    {" "}
                    <MetricsTable
                        isLoading={this.props.countsTableReducerState.isLoading}
                        headerCellOne={"Termen"}
                        headerCellTwo={"Aantal"}
                        metricsList={this.props.countsTableReducerState.zeroHitsList}
                    />{" "}
                </Tab.Pane>
            ),
        },
    ];

    render() {
        return (
            <div className={"countsTable--container"}>
                <Divider hidden />
                <p>De data hieronder getoond zijn van de afgelopen 4 weken. </p>
                <Divider hidden />
                <Tab menu={{ color: "red", attached: false, tabular: false }} panes={this.panes} />
            </div>
        );
    }
}

const mapStateToProps = (state: { countsTableReducer: CountsTableReducerState }) => ({
    countsTableReducerState: state.countsTableReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
    getMostSearchedTerms: () => dispatch(getMostSearchedTerms()),
    getMostFoundProducts: () => dispatch(getMostFoundProducts()),
    getZeroHits: () => dispatch(getZeroHits()),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(CountsTable),
);
