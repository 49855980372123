import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { LoginReducerState } from "../../pages/login/login.reducer";

export const ProtectedRoute = ({ component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props: any) => {
                if (!rest.loginToken) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    );
                }

                return React.createElement(component, props);
            }}
        />
    );
};

const mapStateToProps = (state: { loginReducer: LoginReducerState }) => ({
    loginToken: state.loginReducer.loginToken,
});

export default connect(mapStateToProps)(ProtectedRoute);
