import { SynonymList, SynonymListItem } from "./synonym.model";

export const GET_SYNONYM_LIST = "GET_SYNONYM_LIST";
export const RECEIVE_SYNONYM_LIST = "RECEIVE_SYNONYM_LIST";
export const ADD_SYNONYM_ITEM = "ADD_SYNONYM_ITEM";
export const DELETE_SYNONYM_ITEM = "DELETE_SYNONYM_ITEM";
export const GET_DOWNLOAD_SYNONYMS = "GET_DOWNLOAD_SYNONYMS";
export const RECEIVE_SYNONYM_DOWNLOAD_LIST = "RECEIVE_SYNONYM_DOWNLOAD_LIST";
export const RECEIVE_SYNONYM_STATUS = "RECEIVE_SYNONYM_STATUS";

export function getSynonymList() {
    return { type: GET_SYNONYM_LIST };
}

export function receiveSynonymList(payload: SynonymList) {
    return { type: RECEIVE_SYNONYM_LIST, payload };
}

export function addSynonymItem(payload: SynonymListItem) {
    return { type: ADD_SYNONYM_ITEM, payload };
}

export function deleteSynonymItem(payload: SynonymListItem) {
    return { type: DELETE_SYNONYM_ITEM, payload };
}

export function getDownloadSynonyms() {
    return { type: GET_DOWNLOAD_SYNONYMS };
}

export function receiveSynonymDownloadList(payload: string) {
    return { type: RECEIVE_SYNONYM_DOWNLOAD_LIST, payload };
}

export function receiveSynonymStatus(payload: number) {
    return { type: RECEIVE_SYNONYM_STATUS, payload };
}
