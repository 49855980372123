import axios from "axios";

const backendHost = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = `${backendHost}`;

const VERSION_API = "v1/api/";

export const API_URL = {
    LOGIN: "api/admin/login",
    INDEX_SHOP: `${VERSION_API}admin/index`,
    GET_INDEX_SHOP: `${VERSION_API}admin/index/current`,
    SYNONYMS: `${VERSION_API}admin/synonym/list`,
    SYNONYMS_UPDATE: `${VERSION_API}admin/synonym/update`,
    SYNONYMS_DELETE: `${VERSION_API}admin/synonym/delete`,
    SYNONYMS_DOWNLOAD: `${VERSION_API}admin/synonym/download`,
    MOST_SEARCHED_TERMS: `${VERSION_API}admin/stats/most-searched-terms`,
    MOST_FOUND_PRODUCTS: `${VERSION_API}admin/stats/most-found-products`,
    ZERO_HITS: `${VERSION_API}admin/stats/zero-hits`,
};
