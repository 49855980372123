import "./DownloadList.scss";
import React, { Component } from "react";
import { Button, Header, Icon, Loader } from "semantic-ui-react";
import { RouteComponentProps, withRouter } from "react-router";
import { SynonymReducerState } from "../synonym.reducer";
import { getDownloadSynonyms } from "../synonym.actions";
import { connect } from "react-redux";

interface DownloadListProps extends RouteComponentProps<any> {
    isLoading: boolean;
    downloadList: string;
    getDownloadSynonyms: () => void;
}

export class DownloadList extends Component<DownloadListProps> {
    componentDidUpdate(prevProps: DownloadListProps) {
        if (prevProps.downloadList !== this.props.downloadList) {
            const list = this.props.downloadList;

            const link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([list], { type: "text/plain;charset=utf-8;" }));
            link.download = "synonymList.txt";
            // Append anchor to body.
            document.body.appendChild(link);
            link.click();
            // Remove anchor from body
            document.body.removeChild(link);
        }
    }

    downloadSynonyms = () => {
        this.props.getDownloadSynonyms();
    };

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return (
                <Loader active inline="centered">
                    De synoniemen worden opgehaald.
                </Loader>
            );
        }
        return (
            <div>
                <Header icon>
                    Download hier de synoniemen lijst.
                    <Icon className="fileIcon" name="file text outline" size="tiny" />
                </Header>
                <Button className="download-button" color="red" onClick={this.downloadSynonyms}>
                    Download lijst
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state: { synonymReducer: SynonymReducerState }) => ({
    isLoading: state.synonymReducer.isLoading,
    downloadList: state.synonymReducer.downloadList,
});

const mapDispatchToProps = (dispatch: any) => ({
    getDownloadSynonyms: () => dispatch(getDownloadSynonyms()),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(DownloadList),
);
