import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./App.scss";
import ProtectedRoute from "./components/protected-route/protected-route";
import Login from "./pages/login/Login";
import { Page404 } from "./pages/page404/Page404";
import { Administration } from "./pages/administration/Administration";
import Navigation from "./components/navigation/Navigation";

class App extends Component {
    render() {
        return (
            <div className="app">
                <Router>
                    <Navigation />
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <ProtectedRoute exact path="/" component={Administration} />
                        <Route component={Page404} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
