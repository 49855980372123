import React, { Component } from "react";
import { Tab } from "semantic-ui-react";
import { RouteComponentProps } from "react-router";
import Synonym from "./synonym/Synonym";
import Reindex from "./reindex/Reindex";
import HitsTable from "./countsTable/CountsTable";

interface AdministrationProps extends RouteComponentProps<any> {}

export class Administration extends Component<AdministrationProps, null> {
    panes = [
        {
            menuItem: "Synoniemen",
            render: () => (
                <Tab.Pane attached={false}>
                    <Synonym />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Index beheer",
            render: () => (
                <Tab.Pane attached={false}>
                    <Reindex />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Metrics",
            render: () => (
                <Tab.Pane attached={false}>
                    <HitsTable />
                </Tab.Pane>
            ),
        },
    ];
    render() {
        return (
            <div>
                <Tab menu={{ color: "red", attached: false, tabular: false }} panes={this.panes} />
            </div>
        );
    }
}
