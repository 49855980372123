import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { doReindexShop } from "./reindex.actions";
import { ReindexReducerState } from "./reindex.reducer";
import { ReindexCatalog } from "./ReindexCatalog";
import { Grid, Segment } from "semantic-ui-react";

interface IndexProps extends RouteComponentProps<any> {
    reindexState: ReindexReducerState;
    doReindexShop: () => void;
}

export class Reindex extends Component<IndexProps, {}> {
    componentDidMount(): void {}

    buttonReindex = (shopId: string) => {
        console.log("shopId", shopId);
        if (shopId === "SHOP") {
            this.props.doReindexShop();
        }
    };

    reloadPage = () => {
        window.location.reload();
    };

    render() {
        const { reindexState } = this.props;

        return (
            <div className="content">
                <Segment placeholder>
                    <Grid columns={1} stackable textAlign="center">
                        <Grid.Row verticalAlign="middle">
                            <Grid.Column>
                                <ReindexCatalog handleButtonClick={this.buttonReindex} isLoading={reindexState.SHOP.isLoading} reindexStatus={reindexState.SHOP.reindexStatus} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state: { reindexReducer: ReindexReducerState }) => ({
    reindexState: state.reindexReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
    doReindexShop: () => dispatch(doReindexShop()),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Reindex),
);
