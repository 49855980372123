import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { API_URL } from "../../../api";
import { Action } from "../../action.model";
import {
    ADD_SYNONYM_ITEM,
    DELETE_SYNONYM_ITEM,
    GET_DOWNLOAD_SYNONYMS,
    GET_SYNONYM_LIST,
    getSynonymList,
    receiveSynonymList,
    receiveSynonymDownloadList,
    receiveSynonymStatus,
} from "./synonym.actions";

export function* fetchSynonymList() {
    try {
        const result = yield call(axios.get, API_URL.SYNONYMS);
        const synonymList = result.data;

        yield put(receiveSynonymList(synonymList));
    } catch (error) {
        const synonymStatus = error.request.status;

        yield put(receiveSynonymStatus(synonymStatus));
    }
}

export function* updateSynonym(action: Action) {
    try {
        yield call(axios.put, API_URL.SYNONYMS_UPDATE, action.payload);
        yield put(getSynonymList());
    } catch (error) {
        const synonymStatus = error.request.status;

        yield put(receiveSynonymStatus(synonymStatus));
    }
}

export function* deleteSynonym(action: Action) {
    try {
        yield call(axios.delete, API_URL.SYNONYMS_DELETE, { data: action.payload });
        yield put(getSynonymList());
    } catch (error) {
        const synonymStatus = error.request.status;

        yield put(receiveSynonymStatus(synonymStatus));
    }
}

export function* downloadSynonymsList() {
    try {
        const result = yield call(axios.get, API_URL.SYNONYMS_DOWNLOAD);
        const downloadList = result.data;

        yield put(receiveSynonymDownloadList(downloadList));
    } catch (error) {
        const synonymStatus = error.request.status;

        yield put(receiveSynonymStatus(synonymStatus));
    }
}

function* synonymSaga() {
    yield takeEvery(GET_SYNONYM_LIST, fetchSynonymList);
    yield takeEvery(ADD_SYNONYM_ITEM, updateSynonym);
    yield takeEvery(DELETE_SYNONYM_ITEM, deleteSynonym);
    yield takeEvery(GET_DOWNLOAD_SYNONYMS, downloadSynonymsList);
}

export default synonymSaga;
