import "./TableListItem.scss";
import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { Button, Confirm, Input, Table } from "semantic-ui-react";
import { addSynonymItem, deleteSynonymItem } from "../synonym.actions";
import { SynonymListItem } from "../synonym.model";

interface ListItemProps {
    listItem: SynonymListItem;
    deleteSynonymItem: (synonymListItem: SynonymListItem) => void;
    addSynonymItem: (synonymListItem: SynonymListItem) => void;
}

interface ListItemState {
    isEditing: boolean;
    open: boolean;
    synonyms: string[] | null;
}

export class TableListItem extends React.Component<ListItemProps, ListItemState> {
    constructor(props: ListItemProps) {
        super(props);

        this.state = {
            isEditing: false,
            open: false,
            synonyms: null,
        };
    }

    show = () => this.setState({ open: true });

    handleConfirm = () => {
        this.props.deleteSynonymItem(this.props.listItem);
        this.setState({
            open: false,
        });
    };

    handleCancel = () => this.setState({ open: false });

    handleSynonyms = (event: ChangeEvent) => {
        const target: any = event.target;
        this.setState({ synonyms: target.value.split(",") });
    };

    editListItem = () => {
        this.setState({ isEditing: true });
        this.setState({ synonyms: this.props.listItem.synonyms });
    };

    cancelEditListItem = () => {
        this.setState({ isEditing: false });
        this.setState({ synonyms: this.props.listItem.synonyms });
    };

    saveListItem = () => {
        this.setState({ isEditing: false });
        this.props.addSynonymItem({
            term: this.props.listItem.term,
            synonyms: this.state.synonyms,
        });
    };

    render() {
        const { listItem } = this.props;
        const { isEditing } = this.state;

        return (
            <Table.Row>
                <Table.Cell>{listItem.term}</Table.Cell>
                {!isEditing ? (
                    <Table.Cell>
                        {listItem.synonyms &&
                            listItem.synonyms.map((synonym: string, index: number) => (
                                <span key={index}>
                                    {synonym}
                                    {listItem.synonyms!.length > index + 1 ? "," : ""}
                                </span>
                            ))}
                    </Table.Cell>
                ) : (
                    <Table.Cell>
                        <Input type="text" placeholder="Synonyms" className="input-synonyms" value={this.state.synonyms === null ? "" : this.state.synonyms} onChange={this.handleSynonyms} />
                    </Table.Cell>
                )}

                <Table.Cell textAlign="right">
                    {!isEditing ? (
                        <div>
                            <Button className="edit-button" onClick={this.editListItem}>
                                Bewerken
                            </Button>

                            <Button className="delete-button" onClick={this.show}>
                                Verwijderen
                            </Button>
                            <div>
                                <Confirm
                                    open={this.state.open}
                                    header="Verwijderen"
                                    content='Je staat nu op het punt om een "term" te verwijderen. Weet je het zeker?'
                                    cancelButton="Nee"
                                    confirmButton="Ja"
                                    onCancel={this.handleCancel}
                                    onConfirm={this.handleConfirm}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Button className="save-button" onClick={this.saveListItem}>
                                Opslaan
                            </Button>

                            <Button className="cancel-button" onClick={this.cancelEditListItem}>
                                Anuleren
                            </Button>
                        </div>
                    )}
                </Table.Cell>
            </Table.Row>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    deleteSynonymItem: (synonymListItem: SynonymListItem) => dispatch(deleteSynonymItem(synonymListItem)),
    addSynonymItem: (synonymListItem: SynonymListItem) => dispatch(addSynonymItem(synonymListItem)),
});

export default connect(
    null,
    mapDispatchToProps,
)(TableListItem);
