import React, { ChangeEvent, Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Header, Segment, Grid, Divider, Button, Input, Table, Loader } from "semantic-ui-react";
import { addSynonymItem, getSynonymList } from "./synonym.actions";
import { SynonymListItem } from "./synonym.model";
import { SynonymReducerState } from "./synonym.reducer";
import "./Synonym.scss";
import DownloadList from "./downloadList/DownloadList";
import TableListItem from "./tableListItem/TableListItem";

interface SynonymProps extends RouteComponentProps<any> {
    isLoading: boolean;
    synonymList: SynonymListItem[] | undefined;
    getSynonymList: () => void;
    addSynonymItem: (synonymListItem: SynonymListItem) => void;
}

interface SynonymState {
    term: string;
    synonyms: string[];
    disableButton: boolean;
    termFilter: string;
}

export class Synonym extends Component<SynonymProps, SynonymState> {
    constructor(props: SynonymProps) {
        super(props);

        this.state = {
            term: "",
            synonyms: [],
            disableButton: true,
            termFilter: "",
        };
    }

    componentDidMount(): void {
        this.props.getSynonymList();
    }

    handleTerm = (event: ChangeEvent) => {
        const target: any = event.target;

        this.setState({ term: target.value }, () => {
            this.buttonValidation();
        });
    };

    handleSynonyms = (event: any) => {
        const target: any = event.target;
        const value = target.value ? target.value.split(",") : [];

        this.setState({ synonyms: value }, () => {
            this.buttonValidation();
        });
    };

    handleEnter = (event: any) => {
        if (!this.state.disableButton && event.keyCode === 13) {
            this.addTermAndSynonyms();
        }
    };

    buttonValidation = () => {
        const { term, synonyms } = this.state;

        const hasTerm = term !== "";
        const hasSynonym = synonyms && synonyms.length !== 0;

        if (hasTerm && hasSynonym) {
            this.setState({ disableButton: false });
        } else {
            this.setState({ disableButton: true });
        }
    };

    addTermAndSynonyms = () => {
        this.props.addSynonymItem({
            term: this.state.term,
            synonyms: this.state.synonyms,
        });

        this.setState(
            {
                term: "",
                synonyms: [],
            },
            () => {
                this.buttonValidation();
            },
        );
    };

    handleSearchFilter = (newFilter: any) => {
        this.setState(() => ({
            termFilter: newFilter,
        }));
    };

    render() {
        const { isLoading, synonymList } = this.props;
        const { disableButton, termFilter: searchFilter } = this.state;

        let filterList: SynonymListItem[] = [];
        if (synonymList) {
            filterList = synonymList.filter(option => {
                let synonymItem: string = "";

                for (let i = 0; i < option.synonyms!.length; i++) {
                    synonymItem += option.synonyms![i].toString().toLowerCase();
                }

                const hasSynonym = synonymItem.includes(searchFilter.toLowerCase());
                const hasTerm = option.term.toLowerCase().includes(searchFilter.toLowerCase());

                if (hasTerm || hasSynonym) {
                    return true;
                } else {
                    return false;
                }
            });
        }

        return (
            <div className="content synonym">
                <h1>Synoniemen</h1>

                <Segment placeholder>
                    <Grid columns={2} stackable textAlign="center">
                        <Divider vertical>Of</Divider>

                        <Grid.Row verticalAlign="middle">
                            <Grid.Column>
                                <Header icon>Nieuwe term toevoegen</Header>

                                <Segment.Inline>
                                    <div className="ui input">
                                        <input
                                            className="input-term"
                                            id="mainInput"
                                            type="text"
                                            placeholder="Term"
                                            onChange={this.handleTerm}
                                            onKeyUp={this.handleEnter}
                                            value={this.state.term}
                                            required
                                        />
                                    </div>
                                    <div className="ui input">
                                        <input
                                            id="synonym-input"
                                            className="input-synonyms"
                                            type="text"
                                            placeholder="Synoniem 1, etc"
                                            onChange={this.handleSynonyms}
                                            onKeyUp={this.handleEnter}
                                            value={this.state.synonyms}
                                            required
                                        />
                                    </div>

                                    <div className="ui input">
                                        <Button id="add-button" className="add-term-button" color="red" onClick={this.addTermAndSynonyms} disabled={disableButton}>
                                            Toevoegen
                                        </Button>
                                    </div>

                                    <Divider hidden />
                                </Segment.Inline>
                                <div>
                                    <b>Uitleg:</b> Hetgene dat wordt ingevuld bij "term" wordt in de query vervangen door de woorden die zijn ingevuld bij "synoniemen". Wanneer het woord bij "term"
                                    niet voor komt bij de woorden ingevuld bij "synoniemen" zal hier niet op worden gezocht.
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <h4>Voorbeeld 1</h4>
                                    <u>Term:</u> bbq
                                    <br />
                                    <u>Synoniemen:</u> stokbrood, hamburger
                                    <br />
                                    Hierbij wordt "bbq" in de query vervangen door "stokbrood" en "hamburger". Dit betekent dat er op die laatste twee woorden wordt gezocht. Er komen hierbij dus geen
                                    producten terug voor het woord "bbq".
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <h4>Voorbeeld 2:</h4>
                                    <u>Term:</u> rodekool
                                    <br />
                                    <u>Synoniemen:</u> rodekool, rode kool
                                    <br />
                                    Hierbij wordt "rodekool" in de query vervangen door "rodekool" en "rode kool". Dit betekent dat er zowel met als zonder spatie wordt gezocht. Let hierbij op dat
                                    wanneer er gezocht wordt op "rode kool" er dan niet automatisch ook gezocht wordt op "rodekool". Hiervoor moet nog een extra regel worden toegevoegd, namelijk "rode
                                    kool => rode kool, rodekool".
                                </div>
                            </Grid.Column>

                            <Grid.Column>
                                <DownloadList />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>

                <div className="synonymTable">
                    <Table color="red">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width="three">Termen</Table.HeaderCell>
                                <Table.HeaderCell>Synoniemen</Table.HeaderCell>
                                <Table.HeaderCell width="five" textAlign="right">
                                    <Input
                                        className="search-term"
                                        name="search"
                                        size="large"
                                        icon="search"
                                        onChange={e => {
                                            this.handleSearchFilter(e.target.value);
                                        }}
                                        placeholder="Zoeken"
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        {isLoading ? (
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell colSpan="3">
                                        <Loader active inline="centered" size="medium">
                                            De termen en synoniemen lijst worden opgehaald.
                                        </Loader>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        ) : (
                            <Table.Body>
                                {filterList.length > 0 && filterList.map((listItem: SynonymListItem, index: number) => <TableListItem listItem={listItem} key={`synonymListItem-${index}`} />)}
                            </Table.Body>
                        )}
                    </Table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: { synonymReducer: SynonymReducerState }) => ({
    isLoading: state.synonymReducer.isLoading,
    synonymList: state.synonymReducer.synonymList,
});

const mapDispatchToProps = (dispatch: any) => ({
    getSynonymList: () => dispatch(getSynonymList()),
    addSynonymItem: (synonymListItem: SynonymListItem) => dispatch(addSynonymItem(synonymListItem)),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Synonym),
);
