import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { API_URL } from "../../../api";
import { DO_REINDEX_SHOP, receiveReindexStatusShop } from "./reindex.actions";

export function* fetchReindexShop() {
    try {
        const result = yield call(axios.post, API_URL.INDEX_SHOP);
        const reindexStatus = result.status;

        yield put(receiveReindexStatusShop(reindexStatus));
    } catch (error) {
        const reindexStatus = error.request.status;

        yield put(receiveReindexStatusShop(reindexStatus));
    }
}

function* reindexSaga() {
    yield takeEvery(DO_REINDEX_SHOP, fetchReindexShop);
}

export default reindexSaga;
