import React, { ChangeEvent, FormEvent } from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { Button, Form, Grid, Header, Image, Message, Segment } from "semantic-ui-react";
import Logo from "../../images/logo/Logo-avatar.svg";
import { saveLogin } from "./login.actions";
import { LoginSchema } from "./login.model";
import { LoginReducerState } from "./login.reducer";

interface LoginProps extends RouteComponentProps<any> {
    saveLogin: (loginSchema: LoginSchema) => void;
    loginToken: string | null;
    loginError: string | null;
}

interface LoginState {
    username: string;
    password: string;
    disableButton: boolean;
}

export class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);

        this.state = {
            username: "",
            password: "",
            disableButton: true,
        };
    }

    handleUserName = (event: ChangeEvent) => {
        const target: any = event.target;
        this.setState({ username: target.value }, () => {
            this.buttonValidation();
        });
    };

    handlePassword = (event: ChangeEvent) => {
        const target: any = event.target;
        this.setState({ password: target.value }, () => {
            this.buttonValidation();
        });
    };

    handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        this.props.saveLogin({
            username: this.state.username,
            password: this.state.password,
        });
    };

    buttonValidation = () => {
        const { username, password } = this.state;

        const hasUsername = username !== "";
        const hasPassword = password !== "";

        if (hasUsername && hasPassword) {
            this.setState({ disableButton: false });
        } else {
            this.setState({ disableButton: true });
        }
    };

    render() {
        const { loginError, loginToken } = this.props;
        const { disableButton } = this.state;

        if (loginToken !== null) {
            return <Redirect to={"/"} />;
        }

        return (
            <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as="h2" color="red" textAlign="center">
                        <Image src={Logo} /> Search beheer login
                    </Header>
                    <Form errorsize="large" onSubmit={this.handleSubmit}>
                        <Segment stacked>
                            <Form.Input
                                className="username-input"
                                fluid
                                icon="user"
                                iconPosition="left"
                                placeholder="Gebruikersnaam"
                                name="username"
                                type="text"
                                required
                                onChange={this.handleUserName}
                            />

                            <Form.Input
                                className="password-input"
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Wachtwoord"
                                name="password"
                                type="password"
                                required
                                onChange={this.handlePassword}
                            />

                            {loginError !== null && <Message negative>Het opgegeven gebruikersnaam en/of wachtwoord is onjuist.</Message>}

                            <Button className="login-button" id="form-button" color="red" fluid size="large" disabled={disableButton}>
                                Inloggen
                            </Button>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapStateToProps = (state: { loginReducer: LoginReducerState }) => ({
    loginToken: state.loginReducer.loginToken,
    loginError: state.loginReducer.loginError,
});

const mapDispatchToProps = (dispatch: any) => ({
    saveLogin: (loginSchema: LoginSchema) => dispatch(saveLogin(loginSchema)),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Login),
);
