import { MostFoundProducts, MostSerchedTerms, ZeroHits } from "./countsTable.model";

export const GET_MOST_SEARCHED_TERMS = "GET_MOST_SEARCHED_TERMS";
export const RECEIVE_MOST_SEARCHED_TERMS_LIST = "RECEIVE_MOST_SEARCHED_TERMS_LIST";
export const GET_MOST_FOUND_PRODUCTS = "GET_MOST_FOUND_PRODUCTS";
export const RECEIVE_MOST_FOUND_PRODUCTS_LIST = "RECEIVE_MOST_FOUND_PRODUCTS_LIST";
export const GET_ZERO_HITS = "GET_ZERO_HITS";
export const RECEIVE_ZERO_HITS_LIST = "RECEIVE_ZERO_HITS_LIST";
export const RECEIVE_HITS_STATUS = "RECEIVE_HITS_STATUS";

export function getMostSearchedTerms() {
    return { type: GET_MOST_SEARCHED_TERMS };
}

export function receiveMostSearchedTermsList(payload: MostSerchedTerms) {
    return { type: RECEIVE_MOST_SEARCHED_TERMS_LIST, payload };
}

export function getMostFoundProducts() {
    return { type: GET_MOST_FOUND_PRODUCTS };
}

export function receiveMostFoundProductsList(payload: MostFoundProducts) {
    return { type: RECEIVE_MOST_FOUND_PRODUCTS_LIST, payload };
}

export function getZeroHits() {
    return { type: GET_ZERO_HITS };
}

export function receiveZeroHitsList(payload: ZeroHits) {
    return { type: RECEIVE_ZERO_HITS_LIST, payload };
}

export function receiveHitsStatus(payload: number) {
    return { type: RECEIVE_HITS_STATUS, payload };
}
